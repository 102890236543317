import { withPublicWrapper } from '../components/public/Wrapper/withPublicWrapper';
import LoginContainer from '../components/private/Login/LoginContainer';
import React from 'react';
import Head from 'next/head';

const LoginPage = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>Hitch and Hike - Login</title>
      </Head>

      <LoginContainer redirectPath="/dashboard" />
    </>
  );
};

export default withPublicWrapper(LoginPage);
